@use '@/styles/utils/mixins.scss' as *;

.categoryCard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;

  @include tab-des() {
    grid-template-columns: repeat(1, 1fr);
  }
  .categoryContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
    padding-bottom: 2.5rem;
    border-bottom: 0.063rem solid $primaryAlt;
    grid-column: span 2;
    width: 100%;
    @include tab-des() {
      grid-template-columns: repeat(1, 1fr);
      grid-column: span 1;
    }
  }
  .categoryContentContainer {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
  }
  .description {
    font-weight: 400;
  }
  .productLists {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    list-style: none;
    padding: 0;
    margin: 0;
    .productListLink {
      font-size: 1rem;
      font-weight: bold;
      color: $primary;
      transition: all 0.2s ease-in-out;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }
  .categoryImageContainer {
    @include tab-des() {
      max-height: 16.25rem;
    }
    .categoryImage {
      max-width: 100%;
      height: auto;
      border-radius: 1.25rem;
      @include tab-des() {
        object-fit: cover;
        height: 100%;
        width: 100%;
        object-position: center center;
      }
    }
  }
}
